<template>
  <div class="animated fadeIn">
    <b-card>
      <b-row>
        <ResponseAlert ref="response" />
        <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
          <custom-button
            text="Tambah Admin"
            :url="'/'+ config.uri+'/add'"
            permission="add/admin"
            class-name="btn btn-primary display-table-cell pull-right rounded mb-1 mb-md-0 btn-width-responsive"
          />
          <div class="col-md-3 col-12 pull-right no-padding px-0">
            <div class="input-group">
              <input
                v-model="config.search"
                type="search"
                placeholder="Pencarian"
                class="form-control form-control-sm"
                @keyup.enter="config.page = 1; get()"
              >
              <div class="input-group-append">
                <div
                  class="input-group-text"
                  @click="config.page = 1; get()"
                >
                  <feather-icon icon="SearchIcon" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-col sm="12">
          <div class="table-responsive">
            <table class="table b-table">
              <thead>
                <tr>
                  <th
                    v-for="(row, key) in config.headers"
                    :key="key"
                    :width="row.width"
                    :class="row.class"
                  >
                    <a
                      v-if="row.value"
                      class="sort dark-font"
                      href="javascript:void(0)"
                      @click="sort(row.value)"
                    >
                      {{ row.title }}
                      <i
                        :id="row.value"
                        class="fa fa-sort"
                      />
                    </a>
                    <a
                      v-else
                      :id="row.value"
                      class="sort dark-font"
                      href="javascript:void(0)"
                    >
                      {{ row.title }}
                    </a>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="config.total_data">
                  <tr
                    v-for="(row, key) in config.rows"
                    :key="key"
                  >
                    <td v-if="row.profile">
                      {{ row.profile.fullName }}
                    </td>
                    <td>{{ row.email }}</td>
                    <td>
                      <span v-if="row.role">
                        {{ row.role.name }}
                      </span>
                    </td>
                    <td>{{ row.status ? 'Aktif' : 'Tidak Aktif' }}</td>
                    <td align="center">
                      <custom-button
                        permission="detail/admin"
                        :url="`/${config.uri}/${row.id}`"
                        title="Detail"
                        class-name="btn-action"
                      >
                        <feather-icon icon="EyeIcon" />
                      </custom-button>
                      <custom-button
                        permission="edit/admin"
                        :url="`/${config.uri}/edit/${row.id}`"
                        title="Edit"
                        class-name="btn-action px-1"
                      >
                        <feather-icon icon="EditIcon" />
                      </custom-button>
                      <custom-button
                        type="button"
                        permission="delete/admin"
                        title="Delete"
                        class-name="btn-action bg-transparent border-0 text-primary p-0"
                        @click="destroy(row.id)"
                      >
                        <feather-icon icon="TrashIcon" />
                      </custom-button>
                    </td>
                  </tr>
                </template>
                <tr v-if="!config.total_data">
                  <td
                    :colspan="config.headers.length"
                    align="center"
                  >
                    No data available.
                  </td>
                </tr>
              </tbody>
              <tfoot v-if="config.total_data">
                <tr>
                  <td
                    :colspan="config.headers.length"
                    class="p-0"
                  >
                    <div class="d-flex justify-content-between mt-2">
                      <b-form-select
                        v-model="config.per_page"
                        :options="perPageOptions"
                        class="w-auto"
                      />
                      <b-pagination
                        v-model="config.page"
                        :total-rows="config.total_data"
                        :per-page="config.per_page"
                        @change="gotoPage"
                      />
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BPagination, BFormSelect,
} from 'bootstrap-vue'
// import table from '@/utils/table.js'
import api from '@/utils/api'

export default {
  name: 'ListAdmin',
  metaInfo: {
    title: 'List Admin',
  },
  components: {
    BCard,
    BRow,
    BCol,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      config: {
        uri: this.$route.meta.link,
        api: api.admin,
        rows: [],
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
        headers: [{
          title: 'Nama',
          value: 'profile.firstName',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Email',
          value: 'email',
          align: 'text-left',
          width: '30%',
        }, {
          title: 'Role',
          value: 'role.name',
          align: 'text-left',
          width: '20%',
        }, {
          title: 'Status',
          value: 'status',
          align: 'text-left',
          width: '15%',
        }, {
          title: 'Aksi',
          value: '',
          class: 'text-center',
          width: '15%',
        }],
      },

    }
  },
  computed: {
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
  },
  watch: {
    perPage() {
      this.config.page = 1
      this.get()
    },
  },
  created() {
    const _ = this
    _.get()
  },
  methods: {
    get() {
      this.$table.get(this.config)
    },
    sort(orderby) {
      if (orderby !== '') this.$table.sort(this.config, orderby)
    },
    gotoPage(page) {
      this.$table.gotoPage(this.config, page)
    },
    destroy(id) {
      this.$swal({
        title: 'Perhatian',
        text: 'Apakah anda yakin akan menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$axios.delete(`${api.admin}/${id}`)
            .then(res => {
              this.$refs.response.setResponse(res.data.message, 'success')
              this.get()
            })
            .catch(error => this.$refs.response.setResponse(error.response.data, 'danger'))
        }
      })
    },
  },
}
</script>
